import { BaseExchange, BaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'
import * as SDK from '~/sdk'

export type AccessToken = {
  accessToken: string
  tokenType: string
  expiresAt: string
}

export interface Request {
  sessionId: string
  target: SDK.Internal.V1.Auth.BankId.TargetUnit
}

export interface Response {
  accessToken: AccessToken
}

export async function post(payload: BaseExchange<Request>): Promise<BaseResponse<AccessToken>> {
  return await API.post<BaseExchange<Request>, BaseResponse<AccessToken>>('internal/v1/auth/bank-id/session', payload)
}
