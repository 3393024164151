
import { BaseExchange, BaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'

export type Entity = {
  url: string,
}

export interface PostPayload {
  dateFrom: string
  dateTo: string
  fileType: 'csv' | 'pdf'
  productType: 'BUSINESS_LOAN' | 'FACTORING' | 'QUICK_LOAN',
}

export async function get(payload: BaseExchange<PostPayload>): Promise<BaseResponse<Entity>> {
  return await API.get('internal/v1/company/accountings/download', { params: payload.data})
}
