import { TagManagerArgs } from 'react-gtm-module'

export const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH || 'Ce2CL2a7b1rb3ExSS47yzQ'
export const GTM_PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW || 'env-2'
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || 'GTM-NJMMC9V'
export const ENABLED = process.env.NEXT_PUBLIC_GTM_ENABLED || true

export const Config: TagManagerArgs = {
  preview: GTM_PREVIEW,
  auth: GTM_AUTH,
  gtmId: GTM_ID,
}
