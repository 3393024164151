
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { Config as GtmConfig, ENABLED as GtmEnabled, } from 'config/gtm'
import { ThemedHead } from '~/components/ThemedHead/ThemedHead'
import { AuthProvider } from '~/context/AuthContext'
import { useValidation } from '~/hooks/validation'
import TagManager from 'react-gtm-module'
import type { AppProps } from 'next/app'
import { RecoilRoot } from 'recoil'
import { useEffect } from 'react'
import { setLocale } from 'yup'

import '~/public/fonts.css'
import '@monto/tailwindcss/default.css'

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const { config } = useValidation()

  setLocale(config)

  const themedHeadHref = process.env.NEXT_PUBLIC_GOOGLE_WEB_FONT_URL
    || 'https://fonts.googleapis.com/css2?family=Source+Code+Pro&family=Source+Sans+Pro:wght@400;700&family=Source+Serif+Pro:wght@700&display=swap'

  useEffect(() => {
    if (GtmEnabled) {
      TagManager.initialize(GtmConfig)
    }
  }, []);

  return (
    <RecoilRoot>
      <AuthProvider>
        <div>
          <ThemedHead href={themedHeadHref} />

          <div className="text-base antialiased font-body">
            <Component {...pageProps} />
          </div>
        </div>
      </AuthProvider>
    </RecoilRoot>
  )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  