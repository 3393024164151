import { BaseResponse, BaseExchange } from '~/sdk/shared'
import API from '~/sdk/client'

export type ProviderName = 'visma' | 'fortnox' | 'billogram' | 'peaccounting' | 'bl'

export type CredetialField = {
  name: string,
  type: 'TEXT' | 'EMAIL' |'NUMBER' | 'SECRET',
  format: 'ORGANISATION_NUMBER' | 'GUID',
  value: string
}

export type InputField = {
  name: string,
  value: string
}
export interface Provider {
  id: number,
  name: ProviderName,
  displayName: string,
  enabled: boolean,
  label: string,
  logo: string,
  authType: 'REDIRECT' | 'CREDENTIALS',
  fields: CredetialField[]
}

export interface ConnectRequest {
  fields: InputField[]
}

export type ConnectResponse = {
  authType: string,
  connectUrl: string,
  status: 'DONE' | 'PENDING',
}

export async function get(provider: ProviderName): Promise<BaseResponse<Provider>> {
  return await API.get(`internal/v1/providers/${provider}`)
}

export async function connect(provider: ProviderName, payload?: BaseExchange<ConnectRequest>): Promise<BaseResponse<ConnectResponse>> {
  const data = payload || { data: { fields: [] } }

  return await API({
    url: `internal/v1/providers/${provider}/connect`,
    method: 'post',
    data ,
    headers: {
      'content-type': 'application/json',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  })
}
