import { BaseExchange, BaseResponse } from '~/sdk/shared'
import API from  '~/sdk/client'

export interface Entity {
  factoringReceivableAccount: number | null | undefined
  factoringBankAccount: number | null | undefined
  factoringVatAccount: number | null | undefined
  factoringFeeAccount: number | null | undefined
  interestCostAccount: number | null | undefined
  liabilitiesAccount: number | null | undefined
  enabled: boolean
}

export async function get(): Promise<BaseResponse<Entity>> {
  return await API.get('internal/v1/company/settings/bookkeeping')
}

export async function patch(payload: BaseExchange<Entity>): Promise<BaseResponse<Entity>> {
  return await API.patch('internal/v1/company/settings/bookkeeping', payload)
}
