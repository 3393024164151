import { BaseExchange, EmptyBaseResponse } from '~/sdk/shared'
import API from  '~/sdk/client'

export type Entity = {
  id: number,
  identityNumber: number,
  phone: string,
  name: string,
  email: string,
  active: boolean
}

export interface PostPayload {
  industry: string
  financingIntention: 'CONTINUING_OPERATIONS' | 'CONTINUING_EXPANSION' | 'OTHER'
  financingIntentionOther: string|null
  expectedAmountFrom: number,
  expectedAmountTo: number|null,
  financingRecurrence: 'LT_MONTHLY' | 'MONTHLY' | 'ONCE' | 'UNKNOWN'
  otherDirectIndirectOwners: {
    ownershipPercentage: number
    identityNumber: string
  }[]
  beneficialOwners: string|null
  pepPerson: string|null,
  relatedPepPerson: string|null
}

export async function post(payload: BaseExchange<PostPayload>): Promise<EmptyBaseResponse> {
  return await API.post('internal/v1/company/kyc', payload)
}
