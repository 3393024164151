import useTranslation from 'next-translate/useTranslation'

interface ReturnType {
  config: Record<string, unknown>
}

export function useValidation(): ReturnType {
  const { t } = useTranslation()

  const config = {
    mixed: {
      required: t('common:validations.required')
    },
    string: {
      email: t('common:validations.email'),
      min: ({ min }: { min: number }) => t('common:validations.min', { min })
    },
  }

  return {
    config
  }
}
