import { BaseExchange, BaseResponse } from '~/sdk/shared'
import API from  '~/sdk/client'

export interface Entity {
  accountType: Type
  accountNumber: string
  bank: Bank | null | undefined
}

export enum Type {
  DomesticBank = 'DOMESTIC_BANK',
  Bankgiro = 'BANKGIRO',
  Plusgiro = 'PLUSGIRO',
}

export enum Bank {
  SEB = 'SEB',
  Nordea = 'NORDEA',
  Handelsbanken = 'HANDELSBANKEN',
  Swedbank = 'SWEDBANK',
  Alandsbanken = 'ALANDSBANKEN',
  Lansforsakringar = 'LANSFORSAKRINGAR',
  Danske_bank = 'DANSKE_BANK',
  Skandia = 'SKANDIA',
  Other = 'OTHER'
}

export async function get(): Promise<BaseResponse<Entity>> {
  return await API.get('internal/v1/company/settings/bank-account')
}

export async function patch(payload: BaseExchange<Entity>): Promise<BaseResponse<Entity>> {
  return await API.patch('internal/v1/company/settings/bank-account', payload)
}
