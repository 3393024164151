import { BaseResponse, BaseExchange } from '~/sdk/shared'
import API from '~/sdk/client'

export type VerificationField = 'SOCIAL_SECURITY_NUMBER' | 'EMAIL'
export type VerificationOrigin = 'GRAND_ID' | 'CODE'

export interface Verification {
  origin: VerificationOrigin
  field: VerificationField
}

/**
 * There is more permissions, but we only need this one for now.
 */
export enum Permission {
  AccessAdmin = 'ACCESS_ADMIN',
}

export enum Flags {
  'USER_FORCE_PASSWORD_CHANGE_ON_LOGIN',
  'USER_NO_PASSWORD_CHANGE_ON_LOGIN',
  'USER_NEW_DASHBOARD_EXPERIENCE'
}

export interface Company {
  id: number
  identityNumber: string
  name: string
  profileImageUrl: string
  default: boolean
  active: boolean
}

export interface Entity {
  id: number
  socialSecurityNumber: string
  verifications: Verification[]
  permissions: Permission[]
  companies: Company[]
  email: string
  firstName: string
  lastName: string
  createdAt: string
  updatedAt: string
  phone: string
  mailSetting: boolean
  locale: string
  flags: Flags[]
}

export interface PutPayload {
  phone?: string | null,
  mailSetting?: boolean,
  locale?: string,
}



export async function get(): Promise<BaseResponse<Entity>> {
  return await API.get('internal/v1/user/me')
}


export async function put(payload: BaseExchange<PutPayload>): Promise<BaseResponse<Entity>> {
  return await API.put('internal/v1/user/me', payload)
}
