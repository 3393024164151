import { AxiosResponse } from 'axios'

export interface BaseExchange<T> {
  data: T;
}

export type EmptyBaseResponse = AxiosResponse<never>

export type BaseResponse<T> = AxiosResponse<BaseExchange<T>>

/* eslint @typescript-eslint/no-explicit-any: "off" */
export interface PaginatedBaseResponse<T, F = any> extends BaseExchange<T[]> {
  meta: Meta<F>;
  links: {
    self: string;
    first: string;
    prev: string;
    next: string;
    last: string;
  };
}

export interface ValidationErrorResponse {
  errors: {
    detail: string
    id: string
    rule: string
    source: {
      pointer: string
    }
  }[]
}

export type Meta<F = any> = {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  filters: F;
}

export type Money = {
  amount: number,
  currency: string,
}

/**
 * All available filter operations
 */
export enum FilterOperation {
  GreaterThanEqual = 'GTE',
  LessThanEqual = 'LTE',
  GreaterThan = 'GT',
  LessThan = 'LT',
  Where = 'WHERE',
  Equal = 'EQUAL',
  Like = 'LIKE',
  In = 'IN',
}

type FilterNode = { operation: FilterOperation, value: string | string[] }

type Filter<T extends Record<string, any>> = {
  [key in keyof Partial<T>]: FilterNode[]
}

export type Direction = 'desc' | 'asc'

export interface Sort<T> {
  direction?: Direction,
  column: T,
}

export interface BaseFilterRequest<T, K = Record<string, never>> {
  filters?: Filter<T>
  page?: number
  size?: number
  sort?: Sort<K>
}

export function conditionalFilter(nodes: FilterNode[], condition: boolean): FilterNode[] {
  if (!condition) {
    return []
  }

  return nodes
}

export function buildRequestQuery<T, K>(request?: BaseFilterRequest<T, K>): string {
  const result: string[] = []

  if (request?.filters) {
    for (const field in request?.filters) {
      const filtersPerKey = request.filters[field]

      filtersPerKey.forEach(({ operation, value }) => {
        if (!Array.isArray(value)) {
          value = [value]
        }

        result.push(`filters[${field}:${operation}]=${value.map(v => encodeURIComponent(v))}`)
      })
    }
  }

  return `?page=${request?.page ?? 1}&size=${request?.size ?? 25}${result.length ? `&${result.join('&')}` : ''}${request?.sort ? `&sort=${request.sort.column}&order=${request.sort.direction ?? 'desc' }` : ''}`
}
