import { BaseResponse, Money } from '~/sdk/shared'
import API from '~/sdk/client'
import { Type } from '~/sdk/internal/v1/company/applications'

export interface User {
  identityNumber: string,
  email: string,
  phone: string
}

export interface Company {
  name: string,
  identityNumber: string,
  email: string | null
}

export interface PayoutBankAccount {
  type: 'BANKGIRO' | 'PLUSGIRO' | 'DOMESTIC_BANK',
  number: string,
  bank: string,
}

export interface ApplicationEntity {
  type: Type,
  status: 'PENDING' | 'REJECTED' | 'APPROVED' | 'OFFERED',
  appliedAmount: Money,
  owedAmount: Money | null,
  paidOutAmount: Money | null,
  interest: number | null,
  interestFormatted: string | null,
  originalInterest: number | null,
  amortizationMethod: 'FEE' | 'ANNUITY' | null,
  repaymentTime: number  | null,
  feeBase: boolean | null,
  invoiceIds: number[],
  payPeerWeek: Money | null,
  guarantorIds: number[],
  company: Company,
  payoutBankAccount: PayoutBankAccount,
  autogiroBankAccoountNumber: string,
  personalMessage: string,
  user: User,
  totalSellAmount: Money | null,
  totalSellingFee: Money | null,
  administrationFee: Money | null,
  totalInvoiceAmount: Money | null,
  recieptUrl: string | null,
}

export async function get(id: string): Promise<BaseResponse<ApplicationEntity>> {
  return await API.get(`internal/v2/company/applications/${id}`)
}
